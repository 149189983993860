//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-408:_2252,_6848,_9420,_3728,_9680,_7432,_92,_9624;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-408')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-408', "_2252,_6848,_9420,_3728,_9680,_7432,_92,_9624");
        }
      }catch (ex) {
        console.error(ex);
      }